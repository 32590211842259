/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 31, 2023 */



@font-face {
    font-family: 'poppins_blackregular';
    src: url('poppins-black-webfont.woff2') format('woff2'),
         url('poppins-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins_blackitalic';
    src: url('poppins-blackitalic-webfont.woff2') format('woff2'),
         url('poppins-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsbold';
    src: url('poppins-bold-webfont.woff2') format('woff2'),
         url('poppins-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsbold_italic';
    src: url('poppins-bolditalic-webfont.woff2') format('woff2'),
         url('poppins-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins_extrabolditalic';
    src: url('poppins-extrabolditalic-webfont.woff2') format('woff2'),
         url('poppins-extrabolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins_extralightregular';
    src: url('poppins-extralight-webfont.woff2') format('woff2'),
         url('poppins-extralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins_extralightitalic';
    src: url('poppins-extralightitalic-webfont.woff2') format('woff2'),
         url('poppins-extralightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppinsitalic';
    src: url('poppins-italic-webfont.woff2') format('woff2'),
         url('poppins-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins_lightitalic';
    src: url('poppins-lightitalic-webfont.woff2') format('woff2'),
         url('poppins-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins_medium';
    src: url('poppins-medium-webfont.woff2') format('woff2'),
         url('poppins-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins_mediumitalic';
    src: url('poppins-mediumitalic-webfont.woff2') format('woff2'),
         url('poppins-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins_regular';
    src: url('poppins-regular-webfont.woff2') format('woff2'),
         url('poppins-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppins_semibold';
    src: url('poppins-semibold-webfont.woff2') format('woff2'),
         url('poppins-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}