/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap'); */
@import url("./assets/font/poppins/stylesheet.css");
.wrapper {
  width: 90%;
  margin: 0 auto;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "poppins_regular";
  /* border:1px solid red; */
}
a {
  text-decoration: none;
  color: #000;
}
ul {
  list-style: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input{
  /* padding:3px !important; */
}
textarea {
  resize: none;
}
select {
  -moz-appearance: none;
  background-color: transparent;
  color: inherit;
}
.icon_round{
  background-color: #00C0A5;
  width:10px;
  height:10px;
  box-shadow: inherit !important;
  border:5px solid #fff;
}
.vertical-timeline-element-content.bounce-in{
  width:300px;
}
.vertical-timeline-element-content{
  box-shadow: inherit !important;
}
@media screen and (max-width: 360px){
  .vertical-timeline-element-content.bounce-in{
    width:250px;
  }
}
.error_message{
  color:red;
  font-size: 14px;
}
@media screen and (max-width: 420px){
  .error_message{
    font-size: 12px;
  }
}
.vertical-timeline-element-content p{
  margin: inherit !important;
}
.textField{
  width: 100%;
  height:30px;
}
.container_timeline {
  margin-bottom: 10px;
}
.toast_error{
  z-index: 1200;
}
#demo-simple-select-filled{
  /* background-color: #f4f4f4; */
  border:none;
  outline:none;
  padding:6px 25px;
  height: 10px !important;
  margin-right: 7px !important;

}
